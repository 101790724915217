import axios from 'src/api/axiosConfig'
import { ApiResponse } from 'src/types/apiTypes';
import {
  CanaryPatientCaregiver, CanaryPatientHeightWeight, CanaryPatientMailingAddress,
  CanaryPatientSampleTimezone, NextCanaryPatientActivationStep,
  UserPatientResponse
} from 'src/types/webContracts'
import { AxiosResponse } from 'axios';
import { ApiRoutes } from '../ApiRoutes'


export async function getCanaryAddress(): Promise<AxiosResponse<ApiResponse<CanaryPatientMailingAddress>>> {
  const response = await axios.get(ApiRoutes.Patient.PIQSetup.CanaryAddress);
  return response;
}

export async function getPatientConfig(): Promise<AxiosResponse<ApiResponse<UserPatientResponse>>> {
  const response = await axios.get(ApiRoutes.Patient.GetPatientConfigAsync)
  return response;
}

export async function updateCanaryAddress(address: CanaryPatientMailingAddress): Promise<AxiosResponse<ApiResponse<boolean>>> {
  const response = await axios.post(ApiRoutes.Patient.PIQSetup.CanaryAddress, address);
  return response;
}

export async function getCanaryHeightWeight(): Promise<AxiosResponse<ApiResponse<CanaryPatientHeightWeight>>> {
  const response = await axios.get(ApiRoutes.Patient.PIQSetup.CanaryHeightWeight);
  return response;
}

export async function updateCanaryHeightWeight(heightWeight: CanaryPatientHeightWeight): Promise<AxiosResponse<ApiResponse<boolean>>> {
  const response = await axios.post(ApiRoutes.Patient.PIQSetup.CanaryHeightWeight, heightWeight);
  return response;
}

export async function getCanarySampleTimezone(): Promise<AxiosResponse<ApiResponse<CanaryPatientSampleTimezone>>> {
  const response = await axios.get(ApiRoutes.Patient.PIQSetup.CanarySampleTimezone);
  return response;
}

export async function updateCanarySampleTimezone(bedTime: string): Promise<AxiosResponse<ApiResponse<boolean>>> {
  const response = await axios.post(ApiRoutes.Patient.PIQSetup.CanarySampleTimezone, { bedTime });
  return response;
}

export async function getCanaryCaregiverDetail(): Promise<AxiosResponse<ApiResponse<CanaryPatientCaregiver>>> {
  const response = await axios.get(ApiRoutes.Patient.PIQSetup.CanaryCaregiverDetail);
  return response;
}

export async function updateCanaryCaregiverDetail(careGiver: CanaryPatientCaregiver): Promise<AxiosResponse<ApiResponse<boolean>>> {
  const response = await axios.post(ApiRoutes.Patient.PIQSetup.CanaryCaregiverDetail, careGiver);
  return response;
}

export async function getCanaryActivationNextStep(): Promise<AxiosResponse<ApiResponse<NextCanaryPatientActivationStep>>> {
  const response = await axios.get(ApiRoutes.Patient.PIQSetup.NextCanaryPatientActivationStep)
  return response
}

export async function updateCanaryCompleteBaseStationPrecheck(): Promise<AxiosResponse<ApiResponse<boolean>>> {
  const response = await axios.post(ApiRoutes.Patient.PIQSetup.CanaryCompleteBaseStationPrecheck);
  return response;
}

export async function updateCanaryCompleteBaseStationSetup(): Promise<AxiosResponse<ApiResponse<boolean>>> {
  const response = await axios.post(ApiRoutes.Patient.PIQSetup.CanaryCompleteBaseStationSetup);
  return response;
}

export async function dismisscanaryactivation(): Promise<AxiosResponse<ApiResponse<boolean>>> {
  const response = await axios.post(ApiRoutes.Patient.PIQSetup.Dismisscanaryactivation);
  return response;
}