<script setup lang="ts">
import {useRoute} from 'vue-router';
import {useSessionStore} from 'src/store';
import { i18n } from '@/plugins/i18n';
import {computed} from 'vue';

const route = useRoute();

const sessionStore = useSessionStore();
const hasMedstrat = computed(() => sessionStore.user!.medstratPatientId > 0);

const isDisabled = computed(() => (sessionStore.procedures?.length === 1 && !hasMedstrat) || (route.name !== 'Dashboard.Home' && route.name !== 'Images.MedStrat')),
  selectedProcedure = computed(() => sessionStore.procedures?.find(x => x.eocId === sessionStore.user?.selectedEocId)),
  hasOtherEocAlerts = computed(() => sessionStore.procedures?.some(p => p.eocId !== selectedProcedure.value?.eocId && p.totalAlerts > 0));

const buttonTxt = computed(() => {
  const selectProc = sessionStore.procedures?.find(x => x.eocId === sessionStore.user?.selectedEocId);
  if (selectProc !== undefined) {
    return selectProc.lateralityBodyPartAbbrLocalized;
  }
  else if (hasMedstrat) {
    return i18n.global.t('medstrat_view_xrays');
  }
  return undefined;
})
</script>

<template>
  <zbm-btn v-if="buttonTxt"
           text-color="content-grd-bg-content"
           :disabled="isDisabled"
           :icon="hasOtherEocAlerts ? 'fa-regular fa-bell' : undefined"
           :icon-right="!isDisabled ? 'fa-solid fa-angle-down' : undefined"
           :label="buttonTxt"
           :outline="false"
           :rounded="false" />
</template>

<style lang="scss" scoped>
.q-btn {
  font-weight: map-get($text-weights, bold);
  font-size: initial;
  line-height: 1;

  :deep(.on-left) {
    $bell-bkgd-size: 24px;

    width: $bell-bkgd-size;
    height: $bell-bkgd-size;
    background-color: $accent;
    font-size: 12px;
    line-height: $bell-bkgd-size;
    color: $white;
    text-align: center;
    border-radius: 50%;
  }

  &:disabled,
  .disabled {
    opacity: 1 !important;
  }
}
</style>
