export enum ThemeColors {
  Primary = 'primary',
  Secondary = 'secondary',
  Accent = 'accent',
  Positive = 'positive',
  Negative = 'negative',
  Warning = 'warning',
  Info = 'info',
  Ongoing = 'ongoing',
  Light = 'light',
  Dark = 'dark',
  Muted = 'muted',
  White = 'white'
}

export enum ApiMockModesEnum {
  NoMocks = 'NoMocks',
  AllMocks = 'AllMocks',
  MockUnimplemented = 'MockUnimplemented'
}

export enum Workflow {
  unknown = 0,
  registration = 1,
  deviceOrIp = 2,
  forgotUsername = 3,
  forgotPassword = 4
}

export enum GeneralPurposeVariant {
  Decision = 'decision',
  Disclaimer = 'disclaimer',
  Information = 'information',
  Success = 'success',
}

export enum UserPolicyType {
  UserPolicyAbout,
  UserPolicyEndUserLicenseAgreement,
  UserPolicyMessagingDisclaimer,
  UserPolicyPrivacyPolicy,
  UserPolicySafetyInfo,
  UserPolicySupport,
  UserPolicyGlobalConsent,
  UserPolicyDisclaimer,
  UserPolicyVideoVisitDisclaimer,
  PrivacyPolicyAddtocalendarDisclaimer,
  UserPolicyCheckInDisclaimer,
  CanaryPiqPrivacyPolicy,
  CanaryPiqTermsOfUse,
  CrossBorderDataTransfer
}

export enum EpisodeOfCareStatus {
  Pending = 1,
  Active = 2,
  Discharged = 3
}

export enum ClientFeatureType {
  Messaging = 'Messaging',
  DisplayHeartRate = 'DisplayHeartRate',
  SimplePatientPasscode = 'SimplePatientPasscode',
  Telemed = 'Telemed',
  PainTracking = 'PainTracking',
  OpioidTracking = 'OpioidTracking',
  ROMShoulderAssessment = 'ROMShoulderAssessment',
  NonBAAPrivacyConsent = 'NonBAAPrivacyConsent'
}

// Box

export enum BoxBackgroundColorEnum {
  accent = 'bg-accent',
  grey = 'bg-grey',
  linearGradient = 'bg-linear-gradient',
  secondary = 'bg-secondary',
  transparent = 'bg-transparent',
  white = 'bg-white'
}

export enum BoxBorderRadiusEnum {
  default = 'radius--default',
  lg = 'radius--lg'
}

export enum BoxDropShadowEnum {
  normal = 'dropshadow--normal',
  lg = 'dropshadow--lg',
  xl = 'dropshadow--xl'
}

export enum BoxMarginEnum {
  mb20 = 'q-mb-lg',
  mb40 = 'mb-40'
}

export enum BoxPaddingEnum {
  none = 'p-none',
  p20 = 'p-20',
  p40 = 'p-40',
  p2018 = 'p-20-18',
  p303055 = 'p-30-30-55',
  p453565 = 'p-45-35-65',
  p504535 = 'p-50-45-35',
  p505065 = 'p-50-50-65'
}

export enum BoxWidthEnum {
  xs = 'w--xs',
  md = 'w--md',
  lg = 'w--lg',
  xl = 'w--xl'
}

// Button

export enum ButtonHeightEnum {
  sm = 'q-btn--sm',
  md = 'q-btn--md',
  lg = 'q-btn--lg'
}

export enum ButtonStdColorEnum {
  accent = 'accent-bg',
  accentText = 'accent',
  darkTeal = 'content-trg-secondary-def-bg',
  whiteText = 'content-grd-bg-content'
}

export enum ButtonTypeEnum {
  button = 'button',
  reset = 'reset',
  submit = 'submit'
}

export enum ButtonWidthEnum {
  wFull = 'q-btn--full',
  w270 = 'q-btn--270',
  w300 = 'q-btn--300'
}

// Dialog

export enum DialogType {
  default = 'q-dialog--default'
}

// Form

export enum FormElWidthEnum {
  qFormElNone = '',
  qFormElAuto = 'q-form-el--auto',
  qFormElFull = 'q-form-el--full',
  qFormEl100 = 'q-form-el--100',
  qFormEl125 = 'q-form-el--125',
  qFormEl300 = 'q-form-el--300'
}

export enum FormControlWidthEnum {
  auto = 'form-control--w-auto',
  shorter = 'form-control--w-shorter',
  short = 'form-control--w-short',
  regular = 'form-control--w-regular',
  w100 = 'form-control--w-100'
}

export enum FormControlHeightEnum {
  default = '',
  short = 'form-control--h-short'
}

export enum FormControlModsEnum {
  default = '',
  onlyBtmBrdr = 'form-control--p-no-x form-control--brdr-bottom'
}

export enum FormControlColorSchemeEnum {
  default = '',
  white = 'form-control--white'
}

// Input Group

export enum InputGroupWidthEnum {
  auto = 'input-group--w-auto',
  short = 'input-group--w-short',
  regular = 'input-group--w-regular'
}

export enum InputGroupModsEnum {
  default = '',
  onlyBtmBrdr = 'input-group--p-no-x input-group--brdr-bottom'
}

export enum InputGroupColorSchemeEnum {
  default = '',
  white = 'input-group--white'
}

// Custom Checkbox

export enum CustomCheckboxVariantEnum {
  survey = 'q-checkbox--survey'
}

// Custom Radio

export enum CustomRadioVariantEnum {
  default = '',
  survey = 'custom-radio__survey'
}



export enum AlertType {
  lostContact = 'Canary_BaseStationLostContact',
  setupIncomplete = 'Canary_BaseStationSetupIncomplete'
}

export enum TaskType {
  education = 1,
  survey = 2,
  routine = 3,
  piq = 4
}

export enum PIQTaskIdEnum {
  RosaProfileSetup = 1,
  BaseStationPrecheck = 2,
  BaseStationSetup = 3,
}

export enum StatisticTypeEnum {
  EducationProgress = 'EventType_Education',
  SurveyProgress = 'EventType_Survey',
  ExerciseProgress = 'EventType_Exercises',
}

export enum AIProgressRanks {
  Low = 'Low',
  OnTrack = 'On-Track',
  High = 'High'
}
export enum AIProgressDataTypes {
  StepsAvg = 'AI_AVERAGE_STEPS', // HK Steps
  MaxStepsAvg = 'AI_AVG_MAX_STEPS', // PIQ Qualified Steps
  KneeROMAvg = 'AI_AVG_KNEE_ROM', // PIQ Walking Motion
  WalkSessionCountAvg = 'AI_AVERAGE_WALKING_SESSION_COUNT' // HK Walking Sessions
}

export enum RcvyProgDataCollStatus {
  Pre = 'pre',
  OnGoing = 'ongoing',
  Post = 'post'
}

export enum PIQSetupStatus {
  Undefined,
  CompletedSegment1,
  CompletedSegment2,
  CompletedSegment3,
  Completed
}