<script setup lang="ts">
import {
  defineProps,
  type PropType
} from 'vue';
import {
  ButtonHeightEnum,
  ButtonStdColorEnum,
  ButtonTypeEnum,
  ButtonWidthEnum
} from '@/constants/enums';

const props = defineProps({
  /* Controls the color scheme of the Button. Options are:
   * "accent" - sets the `background-color` to the "accent" (our magenta) and `color` to "white".
   * "accentText" - sets the `background-color` to transparent and `color` & `border-color` to "accent".
   * "darkTeal" - sets the `background-color` to "dark-teal" and `color` to "white".
   * "whiteText" - sets the `background-color` to transparent and `color` & `border-color` to "white".
   */
  color: {
    type: String as PropType<ButtonStdColorEnum>
  },
  disable: {
    type: Boolean
  },
  /* Controls the height of the Button. Options are:
   * "sm" - sets the `height` to 30px with `padding-inline` to 20px.
   * "md" - sets the `height` to 40px with `padding-inline` to 40px.
   * "lg" - sets the `height` to 44px with `padding-inline` to 40px.
   */
  height: {
    type: String as PropType<ButtonHeightEnum>
  },
  href: {
    type: String
  },
  icon: {
    type: String
  },
  iconRight: {
    type: String
  },
  label: {
    type: String
  },
  outline: {
    type: Boolean,
    default: true
  },
  replace: {
    type: Boolean
  },
  rounded: {
    type: Boolean,
    default: true
  },
  square: {
    type: Boolean,
    default: false
  },
  target: {
    type: String
  },
  textColor: {
    type: String,
  },
  to: {
    type: Object as PropType<string | object>
  },
  /* Controls the Button `type`. Button `type` gets nullified if "href" or "to" prop is used. Options are:
   * "button"
   * "reset"
   * "submit"
   */
  type: {
    type: String as PropType<ButtonTypeEnum>,
    default: ButtonTypeEnum.button
  },
  width: {
    type: String as PropType<ButtonWidthEnum>
  }
});
</script>

<template>
  <q-btn :class="[props.width, props.height]"
         :color="props.color"
         :disable="props.disable"
         :href="props.href"
         :icon="props.icon"
         :icon-right="props.iconRight"
         :label="props.label"
         no-caps
         :outline="props.outline"
         :replace="props.replace"
         :ripple="false"
         :rounded="props.rounded"
         :square="props.square"
         :target="props.target"
         :text-color="props.textColor"
         :to="props.to"
         :type="props.type"
         unelevated>
    <slot />
  </q-btn>
</template>

<style scoped lang="scss"></style>
