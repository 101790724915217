import {TaskType} from '@/constants/enums.ts';
import type {Task} from '@/types/webContracts.ts';

export function innerJoin<TLeft, TRight, TKey, TResult>(
  left: TLeft[],
  right: TRight[],
  leftKeySelector: (x: TLeft) => TKey,
  rightKeySelector: (y: TRight) => TKey,
  resultSelector: (x: TLeft, y: TRight) => TResult): TResult[] {

  const results: TResult[] = [];

  const leftCache: [TKey, TLeft][] = left.map(x => [leftKeySelector(x), x]);

  right.forEach(y => {
    const key = rightKeySelector(y);
    const matches = leftCache.filter(tup => tup[0] === key);
    matches.forEach(match => {
      const result = resultSelector(match[1], y);
      results.push(result);
    });
  })

  return results;
}

export function filterTasks(taskType: TaskType, tasks: Task[]): Task[] {
  return tasks.filter((t: any) => t.taskType.valueOf() === taskType.valueOf());
}

export function filterAndSortTasks(taskType: TaskType, tasks: Task[] | null): Task[] {
  let result: Task[] = [];
  if (tasks) {
    const categoryTasks = tasks.filter((x) => x.taskType.valueOf() === taskType.valueOf());

    // Make sure that the completed tasks are grouped at the bottom of the list
    const incompleteTasks = categoryTasks.filter((x) => !x.isComplete);
    const completeTasks = categoryTasks.filter((x) => x.isComplete);

    result = [...incompleteTasks, ...completeTasks];
  }

  return result;
}
