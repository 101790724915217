<script lang="ts" setup>

import {computed, defineProps, defineEmits } from 'vue';
import {PIQSetupStatus} from '@/constants/enums';
import {useI18n} from 'vue-i18n';

const props = defineProps<{
  setupStatus: PIQSetupStatus;
}>();

const {t} = useI18n();
const piqSetupHeaderText = computed(() => {
  if (props.setupStatus === PIQSetupStatus.CompletedSegment1) {
    return t('piq_widget_segment2_header');
  } else if (props.setupStatus === PIQSetupStatus.CompletedSegment2) {
    return t('piq_widget_segment3_header');
  } else if (props.setupStatus === PIQSetupStatus.CompletedSegment3) {
    return t('piq_widget_connected_header');
  } else {
    return t('piq_widget_segment1_header');
  }
});

const piqSetupSubheaderText = computed(() => {
  if (props.setupStatus === PIQSetupStatus.CompletedSegment1) {
    return t('piq_widget_segment2_subheader');
  } else if (props.setupStatus === PIQSetupStatus.CompletedSegment2) {
    return t('piq_widget_segment3_subheader');
  } else if (props.setupStatus === PIQSetupStatus.CompletedSegment3) {
    return t('piq_widget_connected_subheader');
  } else {
    return t('piq_widget_segment1_subheader');
  }
});

const emits = defineEmits(['onClose', 'onClick']);

function progressBarClass(segment: PIQSetupStatus) {
  if (props.setupStatus == null || props.setupStatus == PIQSetupStatus.Undefined) {
    return '';
  } else if (props.setupStatus == PIQSetupStatus.CompletedSegment3) {
    return 'commpleted-progress-bar';
  } else if (props.setupStatus >= segment) {
    return 'incompleted-progress-bar'
  }

  return '';
}

</script>

<template>
  <div
      :class="['col piq-setup-widget', setupStatus === PIQSetupStatus.CompletedSegment3 ? 'completed-color' : 'incompleted-color']">

    <!-- icons and title -->
    <div class="row q-gutter-md items-center no-wrap">

      <div class="col-auto">
        <q-icon v-if="setupStatus === PIQSetupStatus.CompletedSegment3" name="fa-solid fa-smile" size="24px"/>
        <q-icon v-else name="fa-solid fa-exclamation-triangle" size="24px"/>
      </div>

      <div class="col" style="min-width: 0">
        <div
            :class="['h5', 'q-pt-md', setupStatus == PIQSetupStatus.CompletedSegment3 ? 'completed-color' :'incompleted-color', 'text-wrap']">
          {{ piqSetupHeaderText }}
        </div>
        <div class="subheader text-wrap">{{ piqSetupSubheaderText }}</div>
      </div>

      <div class="col-auto cursor-pointer">
        <q-icon v-if="setupStatus === PIQSetupStatus.CompletedSegment3" name="fa-solid fa-times" size="24px"
                @click="emits('onClose')"/>
        <q-icon v-else name="fa-solid fa-chevron-right" size="24px" @click="emits('onClick')"/>
      </div>

    </div>

    <!-- progress bar -->
    <div class="row q-gutter-md no-wrap q-pt-sm">
      <!-- segment 1 -->
      <q-linear-progress rounded size="14px" :class="['q-mb-xs', progressBarClass(1)]"/>

      <!-- segment 2 -->
      <q-linear-progress rounded size="14px" :class="['q-mb-xs', progressBarClass(2)]"/>

      <!-- segment 3 -->
      <q-linear-progress rounded size="14px" :class="['q-mb-xs', progressBarClass(3)]"/>
    </div>

  </div>

</template>

<style lang="scss" scoped>
.piq-setup-widget {
  width: 100%;
  border: $generic-border-widget;
  border-radius: $generic-border-radius;
  padding-block: $field-padding-block-outlined;
  padding-inline: $bs-grid-gutter-xs;

  &.completed-color {
    color: $primary;
    background-color: none;
  }

  &.incompleted-color {
    color: $primitives-action-100;
    background-color: $primitives-action-10;
  }

  .commpleted-progress-bar {
    background-color: $primary;
  }

  .incompleted-progress-bar {
    background-color: $primitives-action-100;
  }

  .subheader {
    color: $body-txt-clr;
  }

  @media (min-width: 480px) {
    padding-inline: $bs-grid-gutter-md;
  }
}

.h5 {
  line-height: $line-height-md;
}

</style>
