import {ApiMockModesEnum} from 'src/constants/enums';
import {Ref, ref, computed} from 'vue';
import {defineStore} from 'pinia';
import {handleApiRequest} from '@/api/handleApiRequest.ts';
import {getContactInformation} from '@/api/endpoints/settingsWeb.ts';
import type {SupportInfoResponse} from '@/types/storeTypes.ts';
import type {ApiResponse} from '@/types/apiTypes.ts';

export const useSettingsStore = defineStore('settings', () => {

  const apiMockMode: ApiMockModesEnum = (import.meta.env.VUE_APP_MOCK_API as ApiMockModesEnum) || ApiMockModesEnum.NoMocks;
  const apiMockDelayResponse: number = parseInt(import.meta.env.VITE_VUE_APP_MOCK_API_DELAY as string, 10) || 0;
  const appTitle: string = import.meta.env.VITE_VUE_APP_TITLE as string;
  const cweUrl = ''; // Fetched from server

  const phone: Ref<string | null> = ref(null);
  const email: Ref<string| null> = ref(null);
  const loading: Ref<boolean> = ref(false);

  const setSupportDetailsMutation = (newPhone:string, newEmail:string) => {
    phone.value = newPhone;
    email.value = newEmail
  };

  const getSupportInfoDetails = async ()=> {
    try {
      const supportDetails:{supportPhone:string, supportEmail:string} | 'undefined' = JSON.parse(sessionStorage.getItem('supportDetails'));
      setSupportDetailsMutation(supportDetails.supportPhone, supportDetails.supportEmail)
    } catch(err){
      loading.value = true
      const response: ApiResponse<SupportInfoResponse>| undefined = await getContactInformation();
      if (response.data !== null) {
        sessionStorage.setItem('supportDetails', JSON.stringify(response?.data))
        setSupportDetailsMutation(response?.data.supportPhone, response?.data.supportEmail)
      }
      loading.value = false
    }
  };
  return {
    phone,
    email,
    loading,
    getSupportInfoDetails,
    apiMockMode,
    apiMockDelayResponse,
    appTitle,
    cweUrl
  }
})
