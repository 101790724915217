<script lang="ts" setup>
import {
  computed,
  defineProps
} from 'vue';

import {useSessionStore} from 'src/store';

const props = defineProps({
  label: {
    type: Object,
    required: true
  },
  to: {
    type: Object,
    required: true
  },
  icon: {
    type: String,
    default: ''
  },
  id: {
    type: Number,
    default: 0
  }
});

const sessionStore = useSessionStore();
const isSelected =  computed(() => {return sessionStore.user?.selectedEocId === props.id});

</script>

<template>
  <zbm-btn :outline="false"
           :rounded="false"
           :square="true"
           :to="props.to">

    <!-- Icon & # of Notifications -->
    <div class="col-auto">
      <q-avatar class="no-border"
                size="50px">

        <!-- Icon -->
        <!-- <Svg :view-box="procedure?.bodyPartIcon?.viewBox"
             :vector-paths="procedure?.bodyPartIcon?.vectorPaths" /> -->
      </q-avatar>
    </div>

    <!-- Content -->
    <div class="col text-left content">
      {{ $t(props.label) }}
    </div>

    <!-- Angle Right -->
    <div class="col-auto">
      <q-icon :color="isSelected ? 'primary' : undefined"
              :name="isSelected ? 'fa-solid fa-circle-check' : 'fa-regular fa-angle-right'"
              :size="isSelected ? '20px' : '28px'" />
    </div>
  </zbm-btn>
</template>

<style lang="scss" scoped>
.q-btn {
  $q-btn-brdr: 2px solid map-get($co-brand-clrs, content-grd-bg-content);

  background-color: map-get($co-brand-clrs, cnt-branded-grd-2);
  font-weight: map-get($text-weights, regular);
  color: map-get($co-brand-clrs, content-grd-bg-content);
  padding: 20px 15px;
  border-block-end: $q-btn-brdr;

  &:first-child {
    border-block-start: $q-btn-brdr;
  }

  .q-avatar {
    background-color: $bkgd-accent-clr;
    color: map-get($co-brand-clrs, content-assets-hero-def);

    // Icon
    svg {
      width: 46px;
      height: 46px;
    }
  }

  // Content
  .content { // [class*="col"]
    font-size: map-get($font-sizes, '20');
    padding-inline: 12px;

    // Sub-Text
    .sub-text { // <span>
      font-size: map-get($font-sizes, '16');
      opacity: 0.7;

      & > span:not(:last-child)::after {
        content: ", ";
      }
    }
  }
  // EOC Modal
  .q-dialog & {
    background-color: initial;
    color: map-get($co-brand-clrs, content-txt-ttl);
    padding: 15px 0;
    border-block-width: 1px;
    border-block-color: $border-clr;

    .q-avatar {
      background-color: map-get($co-brand-clrs, content-assets-hero-def);
      color: $white;

      // # of Notifications
      .notifications { // <div>
        top: -8px;
        left: 0;
        width: 22px;
        height: 22px;
        border: none;
      }
    }

    // Content
    .content { // [class*="col"]
      font-size: map-get($font-sizes, '18');

      // Sub-Text
      .sub-text { // <span>
        font-size: map-get($font-sizes, '14');
        color: map-get($co-brand-clrs, content-txt-subtitle);
      }
    }
  }
}
</style>
