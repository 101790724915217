<script setup lang="ts">
import { useSessionStore } from 'src/store';
import {
  computed,
  defineModel,
} from 'vue';
import EocNavBtn from 'src/components/EocNavBtn.vue';
import NavBtn from '../NavBtn.vue';

const sessionStore = useSessionStore();
const medstratFakeEocId = computed(() => -sessionStore.user!.medstratPatientId);

const procedures = computed(() => sessionStore.procedures);

const showDialog = defineModel({
  type: Boolean,
  required: true
});

const clickMedstrat = () => {
  sessionStore.setSelectedEocId(medstratFakeEocId.value);
}
</script>

<template>
  <zbm-dialog v-model="showDialog" :close-button="true">

    <!-- Header -->
    <div class="row q-dialog__header">
      <div class="col-auto">
        <h5>{{ $t('eoc_my_procedures') }}</h5>
      </div>
    </div>

    <!-- Body -->
    <div class="column q-dialog__body">
      <EocNavBtn v-for="procedure in procedures" :key="procedure.eocId" :procedure="procedure" />
      <NavBtn :id="medstratFakeEocId" v-if="sessionStore.user!.medstratPatientId" @click="clickMedstrat" :to="{ name: 'Images.MedStrat' }" :icon="'fa-solid fa-image'" :label="$t('medstrat_view_xrays')" />
    </div>
  </zbm-dialog>
</template>

<style lang="scss" scoped></style>