<script setup lang="ts">
import {useI18n} from 'vue-i18n';
import {computed, ref} from 'vue';
import {storeToRefs} from 'pinia';
import {useSettingsStore} from '@/store/settingsModule';

const supportDetailsStore = useSettingsStore();
supportDetailsStore.getSupportInfoDetails();
const {phone, email, loading} = storeToRefs(supportDetailsStore);

const {t} = useI18n();

const title = t('We\'re here for you');
const template = 'For additional questions, please contact support.'

const emailLabel = ref(t('button_email'));
const showEmailLink = ref(false);

function onEmailClicked(evt) {
  showPhoneNunmber.value = false;
  evt.preventDefault();
  evt.stopPropagation();
  showEmailLink.value = !showEmailLink.value;
}

const phoneLabel = ref(t('button_call'));
const showPhoneNunmber = ref(false);

function onPhoneClicked(evt) {
  showEmailLink.value = false;
  evt.preventDefault();
  evt.stopPropagation();
  showPhoneNunmber.value = !showPhoneNunmber.value;
}

const showInfo = computed(()=>{
  return showEmailLink.value || showPhoneNunmber.value
})

const showContact = defineModel({
  type: Boolean,
  required: true
});


</script>

<template>
  <zbm-dialog v-model="showContact">

    <!-- Header -->
    <div class="row q-dialog__header">
      <div class="col-auto">

        <!-- Icon -->
        <q-icon color="dark-teal" name="fa-solid fa-triangle-exclamation" size="42px"/>
      </div>
    </div>

    <!-- Body -->
    <div class="row q-dialog__body">
      <div class="col">
        <h3 class="text-weight-semibold q-mb-lg">
          {{ title }} </h3>
        <p v-html="template"/>
      </div>
    </div>

    <!-- Footer (CTA button(s)) -->
    <div class="row q-dialog__footer">
      <div class="col">
        <div class="row justify-center q-gutter-lg">
          <!-- Email Button -->
          <div class="col">
            <zbm-btn :color="$zb.enums.ButtonStdColorEnum.accentText" :height="$zb.enums.ButtonHeightEnum.lg"
                     icon="fa-regular fa-envelope" :label="emailLabel" :href="`mailto:${email}`"
                     @click="onEmailClicked"/>
          </div>
          <!--Phone  Button -->
          <div class="col">
            <zbm-btn :color="$zb.enums.ButtonStdColorEnum.accentText" :height="$zb.enums.ButtonHeightEnum.lg"
                     icon="fa fa-phone" :label="phoneLabel" :href="`tel:${phone}`" @click="onPhoneClicked"/>
          </div>
        </div>
        <div class="row justify-center q-pt-lg">
          <div class="col-auto items-center" style="min-height: 24px;">
            <a v-if="showEmailLink"  :href="`mailto:${email}`">{{ email }}</a>
            <a v-if="showPhoneNunmber" :href="`tel:${phone}`">{{ phone }}</a>
          </div>
        </div>
      </div>
    </div>
  </zbm-dialog>
</template>

<style scoped lang="scss">
.q-dialog--default .q-dialog__footer>[class*=col]{
  margin-block-end: 0;
}
</style>
