<script setup lang="ts">
import {ref} from 'vue';
import MultiViewLayout from 'src/components/layouts/MultiViewLayout.vue';
import {getNextActivationRoute} from 'src/services/piqSetupService';
import {useRouter} from 'vue-router';
import {useQuasar} from 'quasar';
import {useI18n} from 'vue-i18n';

import TheAbandonModal from '@/components/TheAbandonModal.vue';
const showAbandon = ref(false);

import TheContactModal from '@/components/TheContactModal.vue';

const showContact = ref(false);

const {t} = useI18n();
const $q = useQuasar()
const router = useRouter();

async function onNextClick() {
  await getNextActivationRoute($q).then((result) => {
    router.push({name: result});
  });
}

async function onAbandonClick() {
  showAbandon.value = !showAbandon.value;
}

async function onInfoClick() {
  showContact.value = !showContact.value;
}

</script>

<template>
  <q-page padding>
    <MultiViewLayout :onBackClick="onAbandonClick">

      <template v-slot:title>
        {{ t('hbs_flow_title') }}
      </template>

      <template v-slot:heading>
        {{ t('hbs_segment1_intro_title') }}
      </template>
      <template v-slot:auxiliary>
        <zbm-btn-icon @click="onInfoClick()" icon="fa-regular fa-question-circle" size="24px" text-color="dark-teal"
                      data-testid="backArrow"/>
      </template>
      <template v-slot:default>
        <div class="row items-center">
          <div class="col-3"/>
          <div class="col-6">
            <q-img src="img/baseStation/FPO-Knee.png"/>
          </div>
          <div class="col-3"/>
        </div>

        <div class="column q-items-center">

          <div class="col q-pt-lg ">
            <h3 class="text-center">{{ t('hbs_segment1_intro_title') }}</h3>
          </div>

          <div class="col q-pt-lg">
            <p class="text-center">{{ t('hbs_segment1_intro_body') }}</p>
          </div>

          <div class="col q-pt-lg">
            <zbm-btn :color="$zb.enums.ButtonStdColorEnum.darkTeal" :label="t('get_started')" :outline="true"
                     :width="$zb.enums.ButtonWidthEnum.wFull" :height="$zb.enums.ButtonHeightEnum.lg"
                     @click="onNextClick"/>
          </div>
        </div>
      </template>
    </MultiViewLayout>
    <the-abandon-modal v-model="showAbandon"/>

    <the-contact-modal v-model="showContact"/>
  </q-page>
</template>
