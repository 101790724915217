<script setup lang="ts">
import {useI18n} from 'vue-i18n';
import { useRouter } from 'vue-router';

const {t} = useI18n();
const router = useRouter();
const showAbandon = defineModel({
  type: Boolean,
  required: true
});

function abandonClicked(){
  router.replace({name: 'Dashboard.Home'});
}
function closeAbandon() {
  showAbandon.value = false;
}

</script>

<template>
  <zbm-dialog v-model="showAbandon" >

    <!-- Header -->
    <div class="row q-dialog__header">
      <div class="col-auto">

        <!-- Icon -->
        <q-icon color="dark-teal" name="fa-solid fa-triangle-exclamation" size="42px" />
      </div>
    </div>

    <!-- Body -->
    <div class="row q-dialog__body">
      <div class="col">
        <h3 class="text-weight-semibold q-mb-lg">
          {{ t('important_notice') }}
        </h3>
        <p v-html="t('important_notice_body')" />
      </div>
    </div>

    <!-- Footer (CTA button(s)) -->
    <div class="row q-dialog__footer">

      <!-- Okay Button -->
      <div class="col-auto">
        <zbm-btn @click="closeAbandon()" :color="$zb.enums.ButtonStdColorEnum.accentText"
                 :height="$zb.enums.ButtonHeightEnum.lg" :label="t('return_to_setup')"/>
      </div>
      <div class="col-auto">
        <zbm-btn @click="abandonClicked" :color="$zb.enums.ButtonStdColorEnum.accentText"
                 :height="$zb.enums.ButtonHeightEnum.lg" :label="t('complete_later')"/>
      </div>
    </div>
  </zbm-dialog>
</template>

<style scoped lang="scss">

</style>
