<script setup lang="ts">
import {
  defineModel,
  defineProps,
  type PropType
} from 'vue';
import {type SubNavItem} from '@/types/webContracts';
import CustomLabel from '@/components/layouts/SubNavigation/components/CustomLabel.vue';

import {useI18n} from 'vue-i18n';
const {t} = useI18n();

const props = defineProps({
  inlineLabel: {
    type: Boolean,
    default: false
  },
  navItems: {
    type: Array as PropType<Array<SubNavItem>>
  },
  title: {
    type: String,
    default: ''
  },
  useRouteTabs: {
    type: Boolean,
    default: true
  }
});

import {useRoute } from 'vue-router';
const route = useRoute();

const tabsModel = defineModel<number | string | null | undefined>();
</script>

<template>
  <div class="sub-nav-layout">

    <slot name="hbs"></slot>

    <!-- Heading -->
    <div v-if="props.title" class="row items-center mb-24">

      <!-- Title -->
      <div class="col">
        <h1>{{ t(title) }}</h1>
      </div>

      <!-- More Information -->
      <slot name="more-info" />
    </div>

    <!-- Navigation -->
    <div class="row mb-30">
      <div class="col">

        <!--  Navigation Slot -->
        <slot name="navigation">

          <!-- Navigation Items -->
          <q-tabs v-model="tabsModel"
                  active-color="content-trg-secondary-def"
                  align="left"
                  :breakpoint="0"
                  indicator-color="content-trg-secondary-def"
                  :inline-label="props.inlineLabel"
                  no-caps
                  outside-arrows>

            <!-- Route Tab -->
            <template v-if="props.useRouteTabs">
              <q-route-tab v-for="(item, i) in navItems"
                           :disable="item.disable"
                           exact
                           :href="item.href ? item.href : undefined"
                           :icon="item.isCustomLabel ? undefined : item.icon as string"
                           :key="i"
                           :label="item.isCustomLabel ? undefined : item.label"
                           :name="item.value"
                           :ripple="false"
                           :to="item.name ? { name: item.name, params: item.params } : undefined">

                <!-- Custom Label -->
                <template v-if="item.isCustomLabel">
                  <CustomLabel :nav-item="item" />
                </template>
              </q-route-tab>
            </template>

            <!-- Tab -->
            <template v-else>
              <q-tab v-for="(item, i) in navItems"
                     :disable="item.disable"
                     :icon="item.isCustomLabel ? undefined : item.icon as string"
                     :key="i"
                     :label="item.isCustomLabel ? undefined : item.label"
                     :name="item.value"
                     :ripple="false">

                <!-- Custom Label -->
                <template v-if="item.isCustomLabel">
                  <CustomLabel :nav-item="item" />
                </template>
              </q-tab>
            </template>
          </q-tabs>
        </slot>
      </div>
    </div>

    <!-- Navigation Content -->
    <div class="row">
      <div class="col">
        <slot name="content">
          <router-view :key="route.fullPath" v-slot="{ Component }">
            <keep-alive>
              <component :is="Component" />
            </keep-alive>
          </router-view>
        </slot>
      </div>
    </div>
  </div>
</template>
