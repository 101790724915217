<script lang="ts" setup>
import {
  defineProps,
  onMounted,
  type PropType
} from 'vue';
import { useRouter } from 'vue-router';
import { useSessionStore } from '@/store/sessionStore';
import {
  BoxBackgroundColorEnum,
  BoxBorderRadiusEnum,
  BoxPaddingEnum,
  BoxWidthEnum
} from 'src/constants/enums';
import Box from 'src/components/BoxContainer.vue';

const props = defineProps({
  boxBkgdColor: {
    type: String as PropType<BoxBackgroundColorEnum>,
    default: BoxBackgroundColorEnum.white,
  },
  boxBrdrRadius: {
    type: String as PropType<BoxBorderRadiusEnum>,
    default: BoxBorderRadiusEnum.default,
  },
  boxPadding: {
    type: String as PropType<BoxPaddingEnum>,
    default: BoxPaddingEnum.p453565,
  },
  boxWidth: {
    type: String as PropType<BoxWidthEnum>,
    default: BoxWidthEnum.lg,
  },
  loading: {
    type: Boolean
  },
  onBackClick: {
    type: Function,
    default: null,
  },
  showFooter: {
    type: Boolean,
    default: false,
  },
  showBackArrow: {
    type: Boolean,
    default: true,
  }
});

const router = useRouter(),
  sessionStore = useSessionStore();

onMounted(() => {
  document.querySelectorAll('html')[0].scrollTop = 0;

  sessionStore.setIsHeaderVisible(true);
});

</script>

<template>

  <!-- Heading -->
  <div class="row bs-gutter justify-center multi-view-layout__heading">
    <Box class="col-auto" :box-bkgd-color="$zb.enums.BoxBackgroundColorEnum.transparent" :box-width="props.boxWidth">

      <!-- Section Title -->
      <h4 v-if="$slots.title" class="text-weight-semibold text-center q-mb-xs">
        <zbm-skeleton-loader height="26px" :loading="props.loading" width="100%">
          <slot name="title" />
        </zbm-skeleton-loader>
      </h4>

      <!-- Title -->
      <div class="row bs-gutter no-wrap items-center">

        <!-- Back Arrow-->
        <div v-if="showBackArrow" class="col pl-8">
          <zbm-btn-icon @click="() => (onBackClick ? onBackClick() : router.go(-1))" icon="fa-regular fa-chevron-left"
            size="24px" text-color="dark-teal" data-testid="backArrow" />
        </div>

        <!-- Text -->
        <div class="col-auto title">
          <h1 class="text-center">
            <zbm-skeleton-loader height="34px" :loading="props.loading" width="260px">
              <slot name="heading" />
            </zbm-skeleton-loader>
          </h1>
        </div>

        <!-- Balance Out Back Arrow/Auxilary slot -->
        <div class="col text-right pr-8">
          <slot name="auxiliary" />
        </div>
      </div>
    </Box>
  </div>

  <!-- Content -->
  <div class="row bs-gutter justify-center multi-view-layout__content">
    <Box :box-bkgd-color="props.boxBkgdColor" :box-brdr-radius="props.boxBrdrRadius" :box-padding="boxPadding"
      :box-width="boxWidth" class="col-auto">

      <!-- View Unique Content -->
      <slot />
    </Box>
  </div>

  <!-- View Footer Content -->
  <div v-if="showFooter" class="row bs-gutter justify-center">
    <slot name="footer" />
  </div>
</template>

<style lang="scss">
.multi-view-layout {

  // Heading
  &__heading {
    // .row
    margin-block-end: 33px;

    .title {
      // [class*="col"]
      flex-shrink: 1;
      padding-inline: 0;
    }
  }

  // Content
  &__content {
    // .row

    &:not(:last-child) {
      margin-block-end: 32px;
    }
  }
}
</style>
