<script lang="ts" setup>
import { ref, onMounted, onBeforeUnmount, defineProps, defineEmits } from 'vue';
import { getMedStratSigningUrl } from 'src/api/endpoints/loginWeb';
import FullViewLayout from 'src/components/layouts/FullViewLayout.vue';
import { handleApiRequest } from '@/api/handleApiRequest';
import { uid, useQuasar } from 'quasar';
import { useGeneralPurposeModalStore } from '@/store';
import { createDecisionModal } from '@/utils/generalPurposeModalFactory';

enum IFrameEvents {
  OnAuthReady = 'on-auth-ready',
  OnAuthComplete = 'on-auth-complete',
  Authenticate = 'authenticate'
}

const $q = useQuasar()
const generalPurposeModalStore = useGeneralPurposeModalStore()

// state
const srcUri = ref();
const iframeLoaded = ref(false);
const debugVisible = ref(false);
const debugOptionSelected = ref();
const debugOptions = ref([
  {
    label: `${IFrameEvents.OnAuthComplete}: success`,
    value: { eventName: IFrameEvents.OnAuthComplete, payload: { success: true } }
  },
  {
    label: `${IFrameEvents.OnAuthComplete}: fail`,
    value: { eventName: IFrameEvents.OnAuthComplete, payload: { success: false } }
  }
]);

// local variables
const iframeElementRef = ref<HTMLIFrameElement | null>(null);
let _correlationId = null
let _token = null;

const handleIncommingMessage = (message: MessageEvent) => {
  if (message.source === window && !message.data.spoofed) //ignore own messages
    return;

  //Joints event response handlers
  //This section reacts to our own events and emits a response event Joints would send 
  if (message.data.spoofed) {
    if (message.data.eventName?.toLowerCase() === IFrameEvents.Authenticate) {
      postMessage(debugOptionSelected.value.eventName, { ...debugOptionSelected.value.payload, spoofed: true })
      return;
    }
  }

  //PWE event response handlers
  if (message.data.eventName?.toLowerCase() === IFrameEvents.OnAuthReady) {
    const spoofed = !!debugVisible.value && !!debugOptionSelected.value
    postMessage(IFrameEvents.Authenticate, {
      token: _token,
      correlationId: _correlationId,
      spoofed
    })
  } else if (message.data.eventName?.toLowerCase() === IFrameEvents.OnAuthComplete) {
    if (message.data.success) {
      iframeLoaded.value = true
      handleApiRequest((axios) => axios.post('/Login/MedStratAuthenticated', _correlationId), null, $q)
    } else {
      const model = createDecisionModal('image_viewer_error_title', 'image_viewer_error_text')
      generalPurposeModalStore.setModal(model)
    }
  }
}

const getSingedUri = async () => {
  const response = await handleApiRequest(() => getMedStratSigningUrl(), null, $q)
  srcUri.value = `${response.result.baseUrl}?correlationId=${_correlationId}`
  debugVisible.value = response?.result.debug
  _token = response?.result.digest
  _correlationId = response?.result.correlationId
}

const postMessage = (eventName, payload) => {
  if (debugOptionSelected.value && debugVisible.value) {
    window.postMessage({ eventName, ...payload });
  } else if (iframeElementRef.value?.contentWindow) {
    iframeElementRef.value.contentWindow.postMessage({ eventName, ...payload });
  }
}

const triggerDebugEvent = () => {
  postMessage(IFrameEvents.OnAuthReady, {
    ...debugOptionSelected.value.value,
    spoofed: true
  })
}

onMounted(async () => {
  await getSingedUri();

  window.addEventListener('message', handleIncommingMessage);
});

onBeforeUnmount(() => {
  window.removeEventListener('message', handleIncommingMessage);
});

</script>

<template>
  <FullViewLayout withHeader>
    <div class="px-20">
      <q-expansion-item expand-separator icon="fa-solid fa-toolbox" label="Event Testing Console" color="primary"
        v-if="debugVisible" class="mb-20">
        <div class="row items-center py-16 px-16 ">
          <div class="col-auto pt-16 pr-16">
            <zbm-select-with-validation v-model="debugOptionSelected" option-label="label" :options="debugOptions"
              option-value="value" label="Joints Event" />

          </div>
          <div class="col-auto pt-16 pl-16">
            <zbm-btn @click="triggerDebugEvent" :color="$zb.enums.ButtonStdColorEnum.accent"
              :height="$zb.enums.ButtonHeightEnum.lg" label="Trigger Event" />
          </div>
        </div>
      </q-expansion-item>

      <q-skeleton type="rect" widht="100%" height="100%" v-show="!iframeLoaded" />
      <iframe ref="iframeElementRef" id="MedStratIframe" name="MedStratIframe" v-show="iframeLoaded"
        sandbox="allow-forms allow-modals allow-scripts allow-same-origin" :src="srcUri"
        :style="{ width: '100%', height: debugVisible ? '58vh' : '100%', border: 'none' }">
      </iframe>
    </div>
  </FullViewLayout>
</template>
