import axios from 'src/api/axiosConfig'
import type { CountriesListResult, MatomoOptions } from 'src/types/webContracts';
import { ApiRoutes } from '../ApiRoutes'
import type { ApiResponse } from '@/types/apiTypes';
import type { AxiosResponse } from 'axios';

export async function getListofCountries(): Promise<CountriesListResult> {
  const response = await axios.get(ApiRoutes.Login.GetListOfCountries)
  return response.data.result;
}

export async function getBestFitCultureProvider(): Promise<string> {
  const response = await axios.get(ApiRoutes.Settings.GetBestFitCultureProvider)
  return response.data.data;
}
export async function getMatomoOptions(): Promise<MatomoOptions> {
  const response = await axios.get(ApiRoutes.Settings.PreBootSettings)
  return response.data.result;
}

export async function getInstructionsForUseUrl(): Promise<AxiosResponse<ApiResponse<string>>> {
  const response = await axios.get<ApiResponse<string>>(ApiRoutes.Settings.GetInstructionsForUseUrl);
  return response;
}
export async function getContactInformation(): Promise<AxiosResponse<ApiResponse<string>>> {
  const response = await axios.get<ApiResponse<string>>(ApiRoutes.Settings.GetSupportInfo);
  return response;
}
